<template>
    <div class="test">模块3</div>
</template>

<script>
export default {
    name:'address',
}
</script>

<style>
.test{
    height: 100%;
    background: beige;
}
</style>